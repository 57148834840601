@import "fonts.scss";
@import "product_thumbnails.scss";
@import "inquisition.scss";
@import "checkout.scss";
@import "slotpicker.scss";
@import "fave.scss";
@import "buttons.scss";
@import "address.scss";
@import "blog.scss";
@import "contentblocks.scss";
@import "nutrition.scss";
@import "cookin.scss";

@import "header.scss";

// // Debug Layout
// div
// {
//     outline: 1px solid red;
// }

// BS5 underlines every link, which generally looks bad
a {
    text-decoration: none;
}

.fa-solid,
.fa-brands,
.fa-regular {
    line-height: unset!important;
}

.btn-xl {
    --bs-btn-padding-y: 1.25rem;
    --bs-btn-padding-x: 2rem;
    --bs-btn-font-size: 1.5rem;
    --bs-btn-border-radius: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
}

button,
a,
input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.grid-center {
    display: grid!important;
    align-items: center;
    justify-content: center;
}

#mc-main-content-full {
    margin-top: 128px;
    overflow-x: hidden; //Horizontal scrolling begone!

}

.form-control.flatpickrdate {
    //flatpickr make the date component readonly which makes the background grey
    background: #fff!important; 
}

@include media-breakpoint-up(md) {
    .show-md {
        display: block!important;
        height: unset!important;
        transition: none!important;
    }
}

@include media-breakpoint-up(lg) {
    #mc-main-content-full {
        margin-top: $mc-header-full-height;
        &.page-has-newsticker {
            margin-top: calc($mc-header-full-height + $mc-header-above-height);
        }
    }
}

.product-title {
    font-weight: bold;
    color: $primary;
}

.price-row {
    .price {
        font-weight: bold;
        color: $primary;
    }
}

@include media-breakpoint-down(sm) {
    .product-title {
        text-align: center;
    }
    .product-long-description {
        text-align: center;
    }
    .multi-select-header {
        width: 100%!important;
    }
    .chosen-add-buttons {
        flex-direction: row!important;
    }

}


html {
    overflow-x: hidden;
}



#mc-main-content-full,
#mc-main-content {
    min-height: $mc-main-content-min-height;
}

#mc-footer-full {
    min-height: $mc-footer-height;
}
#mc-footer-main {
    min-height: $mc-footer-main-height;
}
#mc-footer-below {
    height: $mc-footer-below-height;
}



body {
    font-family: $brand-font;
    background-color: $body-bg;
    background: $body-bg-image;
}

#mc-main-content {
    background-color: $mc-main-content-bg-color;
}

// Footer
#mc-footer-full {
    background: $mc-footer-bg;
    color: white;

    ul.footer-list {
        padding-left: 0;
        list-style: none;
        a {
            color: white;
            text-decoration: none;
            font-size: 15px;
        }
    }
}




.offcanvas-link {
    &:hover {
        background-color: #ddd;
    }
}


/* Product Attribute Images in Product Inner */
ul.product-attributes-type-set {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	justify-content: left;
	list-style: none;
	margin: 0;
	padding: 0;
	height: auto;
	width: auto;
}

ul.product-attributes-type-set li {
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	align-items: center;
}

a.product-attribute-type {
	font-size: 2em;
	line-height: 1.2em;
	background: #fff;
	color: #fff;
	margin-right: 10px;

    img {
        max-height: 96px;
    }
}

.feature-icon {
    font-size: 18px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    & img { width:20px; height:20px; }
  }
  
.feature-img { margin:10px }

.mini-cart-footer {
    position: fixed;
    bottom: 0;
    right: 1px;
    width: min(100vw, 398px);
    padding: 2px;
    background: white;
    box-shadow: 0 0.5rem 2rem 1px rgb(0 0 0 / 60%) !important;
    button[type="button"],
    a.btn {
        margin: 1px;
        border-radius: 0;
    }
}

.mini-cart-item-image {
    margin-left: -24px; //push it under the number
}
.mini-cart-body {
    margin-bottom: 54px;
}

.mini-cart-checkout-btn {
    border: 2px solid hsl(120deg 50% 43%);
}
.mc-empty_cart-btn {
    border: 2px solid $brand-primary-light;
}

.modal-backdrop {
    z-index: 1044; // this fixes offcanvas + model for some reason
}

.shopping-list-item-delete-btn {
    position: absolute;
    right: 13px;
    z-index: 100;
    display: flex;
    justify-content: end;
    pointer-events: none;

    a{
        pointer-events: all;
    }

  }
@include media-breakpoint-up(md)  {
    .shopping-list-item-delete-btn {
      top: 3px;
      right: 3px;
    }
}

.tr-shop-lists-deleted {
    background-color: #FFEEF0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: none;
    border-left: none;
  }
.tr-shop-lists-deleted p {
text-decoration: line-through;
}


.scrollTop {
    position: fixed;
    right: 1em;
    bottom: 1em;
    width: 64px;
    height: 64px;
    font-size: 1.8em;
    background: rgb(72 72 72 / 60%);
    border-radius: 18px;
    display: none;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    color: white;
    z-index: $zindex-fixed;
}
.scroll-nav {
    .scrollTop {
        display: flex;
    }
}

.postcode-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--bs-danger);

    &.fa-check-circle {
        color: var(--bs-success);
    }
}


.block-heroimage img {
    width: 100%;
}

.block-heroheader {
    font-size: 1.5rem;
    font-weight: 700;
}


.block-heroheader-horizontal {
    font-size: 2rem;
    font-weight: 700;
}

.block-hero-description > p,
.block-hero-footer > p {
    width: 100%;
    margin-bottom: 0;
}


.item-on-sale {
    .item-price {
        color: $on-sale-color;
    }
    .cart-sale-callout {
        display: flex;
    }
}

.item-price {
    font-weight: 700;
}