// INLINE IMAGE BLOCK

.inline-images {
	padding: 5px 0;
	margin-top: 0px;
	margin-bottom: 0px;
	justify-content: center;
	.inline-image-item {
		display: flex;
		justify-content: center;
		text-align: center;
		padding: 1px;
	}
    .image-link-td {
        position:relative;
        text-align: center;
        overflow-x: hidden;
        overflow-y: visible;
    }
    .image-link-title {
        // Make it float above the text
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        // Center it
        display: flex;
        justify-content: center;
        align-items: center;

        // Make it readable
        font-weight: 600;
        font-size: 1.6em;
        color: white;
        text-shadow: 0 0 10px black;
        text-transform: uppercase;
    }
}

// PARALLAX IMAGE BLOCK

.parallax-section {
    position: relative;
}
.parallax-section::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}
@include media-breakpoint-up(sm) {
    .block-parallax-span-screen.parallax-section::before{
        left: calc(-1 * (100vw - 516px)/2);
        width: 100vw;
    }
}
@include media-breakpoint-up(md) {
    .block-parallax-span-screen.parallax-section::before{
        left: calc(-1 * (100vw - 696px)/2);
        width: 100vw;
    }
}
@include media-breakpoint-up(lg) {
    .block-parallax-span-screen.parallax-section::before{
        left: calc(-1 * (100vw - 936px)/2);
        width: 100vw;
    }
}
@include media-breakpoint-up(xl) {
    .block-parallax-span-screen.parallax-section::before{
        left: calc(-1 * (100vw - 1116px)/2);
        width: 100vw;
    }
}
@include media-breakpoint-up(xxl) {
    .block-parallax-span-screen.parallax-section::before{
        left: calc(-1 * (100vw - 1296px)/2);
        width: 100vw;
    }
}


.block-parallax-image-container {
    height: 400px;
    overflow: hidden;
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
}
.block-parallax-image {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    background-attachment: fixed;
    opacity: 0.9;
}

.block-parallax-header {
  color: white;
  font-size: 25px;
  line-height: 1;
  margin-bottom: 5px;
}

.block-parallax-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-width: 100%;
  top: 50%;
  padding: 16px;
  width: 100%;
  transform-style: flat;
  transform: translate3d(0,-50%,0);

  // Make it readable
  font-weight: 600;
  color: white;
  text-shadow: 0 0 10px black;
}

.block-parallax-marketing-message {
text-align: center;
font-size: 56px;
margin-bottom: 22px;
display: block;
box-sizing: border-box;
max-width: 100%;
}


// FEATURED ELEMENT BLOCK


// Featured Element
.block-featuredelement {
    display: flex;
}
.block-featuredelement-imagecontainer {
    height: 400px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.block-featuredelement-image {
    border-radius: 15px;
    padding: 10px;

}
.block-featuredelement-content {
    height: 400px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.block-featuredelement-header {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.block-featuredelement-title {
    width: 100%;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.block-featuredelement-description {
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
}
.block-featuredelement-product {
    width: 100%;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.owl-stage {
    display: flex!important;
}

// Image Gallery
// Image Gallery
.block-imagegallery-row {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    flex-wrap: wrap;
}
.block-imagegallery-col {
    max-height: 300px;
    max-width: 300px;
     > a {
        position: relative;
        height: 100%;
        display: flex;
     }
}
.block-imagegallery-img {
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
}
.block-imagegallery-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.5rem;
    color: white;
    background: rgba(0,0,0,0.5);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 4px 0;
}


.block-blogposts-container {
    display: flex;
    justify-content: center;
}
.block-blogpost-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    width: 33%;
}
.block-blogpost-imagecontainer {
    height: 200px;
    padding: 0 5px;
}
.block-blogpost-image {
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
}
.block-blogpost-category {
    height: 30px;
    font-weight: 700;
}
.block-blogpost-title {
    height: 50px;
    font-size: 1.2em;
    font-weight: 700;
}
.block-blogpost-summary {
    height: 150px;
    overflow-y: hidden;
}
.block-blogpost-link {
    height: 50px;
    width: 100%;
    text-decoration: underline;
    font-weight: 700;
}
// Drop to 2 wide on tablet
@media (min-width: 451px) and (max-width: 991px){
    .block-blogpost-container:nth-child(3) {
        display:none;
    }
    .block-blogpost-container {
        width: 50%;
    }
}
// Switch to vertical layout on mobile
@media (max-width: 450px) {
    .block-blogposts-container {
        flex-direction: column;
    }
    .block-blogpost-container {
        width: 100%;
    }
}

// CATEGORY BLOCK

.categoryblock-content {
    padding: 50px 5px 80px 5px;
}
.categoryblock-container {
    padding: 20px;
}

.categoryblock-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 420px;
    padding-bottom: 30px;

}
.categoryblock-productname {
    display: flex;
    width: 220px;
    flex-direction: column;
    align-items: center;
    height: 50px;
    font-size: 16px;
    font-weight: 700;
}

.categoryblock-productimage {
    width: 220px;
    height: 220px;
}

.categoryblock-productprice {
    text-align: center;
    height: 30px;
    font-size: 18px;
    font-weight: 700;

}

.categoryblock-productbutton .productbutton-modal-variations,
.categoryblock-productbutton .productbutton-modal {
    width: 209px;
    height: 49px;
    border-width: 2px;
    font-size: 18px;
}

.productmodal-qty {
    .add-btn,
    .product-spinner {
        width: 225px;
        height: 58px;

        .quantity-btn,
        button {
            width: calc(100%/3);
        }

        .quantity-btn {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
    
}

.modal-backdrop {
    background-color: #081D32;
    opacity: 0.78!important;
}
.btn-productmodal {
    border: 2px solid $brand-primary;
    font-size: 16px;
    width: 225px;
    height: 58px;
    font-weight: 700;
}

.productmodal-image {
    height: 426px;
    width: 452px;
}
@media screen and (max-width: 767px){
    .productmodal-image {
        height: 200px;
    }
    .productmodal-title {
        font-size: 18px;
    }
}

.productmodal-cart-status {
    width: 225px;
    height: 128px;
}
.productmodal-title {
    font-size: 28px;
    font-weight: 700;
    display: block;
}