@import "theme_variables.scss";
@import "_variables.scss";
@import "bootstrap/scss/bootstrap.scss";
@import "brand.scss";

// Brand overrides go here

// Cap the brand image height because its quite large
@include media-breakpoint-up(md) {
    .mc-brand-img {
        height: 64px;
    }
}
@include media-breakpoint-down(sm) {
    .mc-brand-img {
        margin: 5px;
    }
}
.scroll-nav {
    .mc-brand-img {
        height: unset;
    }
}


// Search button styling
#mc-search-input {
    background: #eaeaea;
    border: none;
    text-align: center;
}

#mc-header-nav {
    font-family: 'Bitter', 'Times', serif;
    border-bottom: none;
    .nav-link {
        font-size: 14px;
        line-height: 1;
    }
}

.inline-search-btn {
    --bs-btn-hover-bg: #eaeaea;
    margin-left: 3px;
    &:hover {
        border: none;
    }
}

#mc-header {
    transition: height 0.1s ease 0s;
}


#mc-header-nav-bar .nav-link.dropdown-toggle.show {
    background-color: #fff;
    color: $primary;
}

.product-thumbnail-current {
    font-size: 25px;
}

h1, h2, h3 {
    font-family: 'Aleo',Helvetica,Arial,Lucida,sans-serif;
}

.product-title {
    color: $stongs-blue;
}

.price-row {
    .price {
        color: $stongs-blue;
    }
}