/*
    <ABOVE FULL>
        <ABOVE>
    <HEADER FULL>
        <HEADER>
        <NAV FULL>
            <NAV>
*/

/* HEADER ABOVE (news ticker bar)*/
#mc-header-above,
#mc-header-above-full{
    height: $mc-header-above-height;
    transition: $header-transition;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zindex-sticky;
}

#mc-header-above-full {
    z-index: $zindex-sticky;
    &.scroll-nav {
        //max-height: 0;
        //min-height: 0;
        //height: 0;
        margin-top: -$mc-header-above-height;

        #mc-header-above {
            //max-height: 0;
            //min-height: 0;
            //height: 0;
            margin-top: -$mc-header-above-height;
        }

        .news-ticker-body {
            display: none;
        }
    }
}

.news-ticker-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

/* HEADER (logo, search bar, dropdowns) */
#mc-header-full {
    position: fixed;
    top: 0;
    
    z-index: $zindex-sticky;
    transition: $header-transition;
    background: $mc-header-full-bg;

    /* if the newsticker is displayed, push the header down */
    &.page-has-newsticker{
        top: $mc-header-above-height;
    }

    &.scroll-nav {
        height: $mc-header-full-height-scroll;
        &.page-has-newsticker {
            margin-top: -$mc-header-above-height;
        }
        #mc-header-main,
        #mc-header-main-left,
        #mc-header-main-center,
        #mc-header {
            height: $mc-header-height-scroll;
        }
        #mc-header-main-right {
            padding-top: 2px;
        }
        .mc-brand-img {
            //max-width: $navbrand-logo-height-small!important;
            max-height: $mc-header-height-scroll;
        }
        #mc-header-search-bar {
            visibility: hidden;
            opacity: 0;
        }
    }
}

#mc-header-main-left,
#mc-header {
    transition: $header-transition;
}

#mc-header-search-bar {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s ease-out;
}

.search-box form {
    width: 200px;
    input {
        border-radius: 0;
    }
    .inline-search-btn {
        background: none;
        width: 36px;
        margin-left: -36px;
        color: #aaa;
    }
    .form-control:focus {
        box-shadow: none;
        .inline-search-btn {
            border-color: lighten($brand-primary, 20%);
        }
    }
}

.mc-brand-img {
    transition: 0.2s ease-out;
    max-height: $mc-header-height;
}



/* HEADER NAV */
#mc-header-nav-full {
    background-color: $mc-header-nav-full-bg-color;
}

#mc-header-nav {
    height: $mc-header-nav-height;

    background: $mc-header-nav-bg;
    border-bottom: $mc-header-nav-border;
    transition: $header-transition;

    a.nav-link {
        color: white;
    }

    a.nav-link:hover {
        color: white;
        background: $mc-header-nav-highlight;
    }

    #mc-header-nav-menu-button {
        background: $mc-header-nav-highlight;
        
    }

    &.header-overflowing {
        #mc-header-nav-core-link {
            display: block!important;
        }
        #mc-header-nav-home-link {
            display: none!important;
        }
    }
}

// Nav Bar
.mc-top-navbar-ul {
    // If the navbar overflows, hide it
    height: $mc-header-nav-height;
}





@include media-breakpoint-up(lg) {
    #mc-header-full {
        height: $mc-header-full-height;
    }
    #mc-header-main,
    #mc-header-main-left,
    #mc-header {
        height: $mc-header-height;
    }
    
}

@include media-breakpoint-down(md) {
    #mc-header-full {
        height: $mc-header-full-height-mobile;
    }
    #mc-header-main,
    #mc-header-main-left,
    #mc-header {
        height: $mc-header-height-mobile;
    }
    .mc-brand-img {
        max-height: $mc-header-height-mobile;
    }
    
}

@include media-breakpoint-down(sm) {
    .cart-button {
        line-height: 1;
    }
}