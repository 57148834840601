// Override any bootstrap or core/_variables.scss variables here.
// eg $brand-primary

/* Site wide colours */
$stongs-white: #ffffff;
$stongs-cream: #f9f9ed;
$stongs-blue: #0069a2;

$brand-primary: $stongs-blue;
$brand-primary-light: #50a5d3;


$body-bg-color: $stongs-cream;
$body-bg-image: url('../../img/brand_background.png');

$mc-header-nav-full-bg-color: $stongs-blue;
$mc-header-nav-highlight: $stongs-blue;

$mc-header-height: 90px;
$mc-header-nav-height: 32px;

$min-contrast-ratio:   3 !default;
$border-radius:               .175rem;
$enable-negative-margins: true;
